import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Section from "../../../components-v2/Base/Section";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import heroBg from "../../../images/hero-images/home-decor-hero.png";
import LeadForm from "../../../components/Forms/LeadForm";
import { noMoneyDownData } from "../../../data/forms/lead-form";
import { extractDefaultValues } from "../../../utils/form-utils";
import bg1 from "../../../images/svg/vector-10.svg";
import Image from "../../../components/ImageQuerys/RandomImages";
import { OFFERS_LP } from "../../../utils/firebase/forms";
import CapterraBanner from "../../../components-v2/CapterraBanner";

function NoMoneyDownPage({}) {
  return (
    <Layout
      className="non-fixed-width"
      isLanding
      useNewLPHeader
      phoneNumber="+13035240930"
      phoneText="(303) 524-0930"
    >
      <SEO
        title="Restaurant POS System | Free Hardware Offer | SpotOn"
        description="Free POS hardware for qualifying restaurants. Streamline ops, create better guest experiences, retain employees, and boost revenue. Talk to an expert."
        image={`https://spoton.com/${heroBg}`}
      />

      <Section className="grid grid-cols-1 lg:grid-cols-2 mt-20 md:mt-32 items-center gap-10">
        <div className="text-center lg:text-left">
          <Heading
            level={1}
            injectionType={1}
            withBlueSymbol
            className="max-w-[550px] mx-auto lg:mx-0"
          >
            Get the #1 rated restaurant POS{" "}
            <span className="text-primary">for free</span>
          </Heading>
          <div className="text-black-300 text-[20px] max-w-[400px] xl:max-w-[510px] mt-10 mx-auto lg:mx-0">
            We get it. Switching your POS can be a pain. We’re making it easy.
          </div>
          <div className="hidden lg:block text-black-300 text-[20px] max-w-[400px] xl:max-w-[530px] mt-10 mx-auto lg:mx-0">
            If your restaurant makes more than $400K in annual sales, upgrade
            your tech and we'll cover the cost of your hardware. Full-service.
            Counter-service. One station or twenty. We’ve got you covered.
          </div>

          <div
            className="w-full my-6 hidden lg:block "
            style={{ borderTop: "1px solid #D6DADF" }}
          />

          <p className="hidden lg:block text-black-300 text-[12px]">
            *New SpotOn clients with over $400K in annual sales receive free
            restaurant point-of-sale hardware through April 30, 2023. Offer
            applies to new SpotOn Restaurant clients only and may not be
            combined with any other discounts, offers, or promotions. Offer
            valid in Pennsylvania, Florida, Washington, and Washington, D.C.
            only.
          </p>
        </div>

        <div className="relative -mt-12 md:-mt-4 lg:mt-0">
          <LeadForm
            className="relative z-[1]"
            plain
            noHeader
            formName="Lead Form"
            displayForm
            formData={noMoneyDownData}
            initialValues={extractDefaultValues(noMoneyDownData)}
            onValuesChange={() => null}
            useFormValueAsDataLayerEventName
            formValueForDataLayerEventName="industry"
            dataLayerEventNameAppendix="-lead"
            withLineOfBusinessOption={false}
            lineOfBusiness="Hospitality"
            productInterest=""
            collection={OFFERS_LP}
            thankYouPath="/lp/free-hardware/thank-you"
          />

          <div
            className="w-full my-6 lg:hidden "
            style={{ borderTop: "1px solid #D6DADF" }}
          />

          <p className="lg:hidden max-w-[500px] mt-6 mx-auto text-center text-black-300 text-[12px]">
            *New SpotOn clients with over $400K in annual sales receive free
            restaurant point-of-sale hardware through April 30, 2023. Offer
            applies to new SpotOn Restaurant clients only and may not be
            combined with any other discounts, offers, or promotions. Offer
            valid in Pennsylvania, Florida, Washington, and Washington, D.C.
            only.
          </p>
          <img
            src={bg1}
            alt="blue circle"
            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
          />
        </div>
      </Section>

      <CapterraBanner
        className="mt-10 lg:mt-20"
        imageName="capterra-pos.png"
        title={`The <span class="text-primary">top-rated POS</span> company according to actual user reviews<span class="text-primary">.</span>`}
        subtitle="Real restaurant operators awarded SpotOn the highest marks for restaurant point-of-sale across every quality measured, beating out Square, Clover, and Toast."
        withCta={false}
      />

      <Section className="mt-16 lg:mt-24 mb-20">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 max-w-[1120px] mx-auto">
          <div className="bg-primary-30 rounded-[16px] p-8 md:p-12 flex flex-col items-center justify-center">
            <p className="text-black text-p md:text-[24px]">
              “SpotOn is not just a POS. It's a communications device. It's a
              reporting device. It's a labor management tool. If you’re looking
              for a POS system that going to help you run your business, it’s
              SpotOn.”
            </p>

            <div className="mt-4 xl:mt-12 flex self-start">
              <div className="w-[80px]">
                <Image imageName="gotham-logo-1.png" />
              </div>
              <div className="ml-4">
                <div className="text-primary text-p md:text-[24px]">
                  Bret Csencsitz
                </div>
                <div className="text-black-200 text-[12px] md:text-[14px]">
                  Managing Partner, Gotham Restaurant
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <Image imageName="no-money-down.png" />
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export default NoMoneyDownPage;
