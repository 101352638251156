import React from "react";
import { string, bool } from "prop-types";
import Section from "../Base/Section";
import Image from "../../components/ImageQuerys/HeroImages";
import CtaPrimary from "../Base/CtaPrimary";

const CapterraBanner = ({
  className,
  imageName,
  title,
  subtitle,
  withCta,
  ctaTarget,
  ctaLabel,
  noBackground,
  titleWidth,
}) => {
  return (
    <Section className={`bg-white ${className}`} animations={false}>
      <h2
        className={`
          text-black text-h2-small xl:text-h2
          text-center font-bold 
          mx-auto
           ${titleWidth || "max-w-[690px]"}
        `}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      {subtitle && (
        <p className="text-black-200 text-p mt-6 text-center mx-auto max-w-[800px]">
          {subtitle}
        </p>
      )}

      <div className="relative">
        {!noBackground && (
          <Image
            style={{ position: "absolute !important" }}
            imageName="ellipse.png"
            className="capterra-banner-bg absolute -top-10 md:-top-20 lg:-top-28 xl:-top-40 2xl:-top-52 left-0 right-0"
          />
        )}
        <Image
          imageName={imageName}
          className="ml-[-40px] md:ml-[-60px] lg:ml-[-130px] xl:ml-[60px] 2xl:ml-[100px] mt-10 lg:mt-16 max-w-[1000px]"
        />
      </div>

      {withCta && (
        <CtaPrimary
          className="mx-auto mt-20 relative"
          title={ctaLabel}
          target={ctaTarget}
          newTab
        />
      )}
      <style jsx="true" global>{`
        .capterra-banner-bg {
          position: absolute !important;
        }
      `}</style>
    </Section>
  );
};

CapterraBanner.propTypes = {
  className: string,
  imageName: string.isRequired,
  title: string.isRequired,
  subtitle: string,
  withCta: bool,
  ctaLabel: string,
  ctaTarget: string,
  noBackground: bool,
  titleWidth: string,
};

CapterraBanner.defaultProps = {
  className: "",
  subtitle: "",
  withCta: false,
  ctaLabel: null,
  ctaTarget: null,
  noBackground: false,
  titleWidth: null,
};

export default CapterraBanner;
